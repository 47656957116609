import React, { useState, useEffect } from "react";
import abi from "./abi.json";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";

// import videoPop from "./Imgs/Video/VideoPop.mp4";

const REACT_APP_CONTRACT_ADDRESS = "0xa48837feaB00D9c9157F5C9cC16929685AB07696";
const SELECTEDNETWORK = "137";
const SELECTEDNETWORKNAME = "Polygon  Mainnet";
const nftquantity = 333;

function Mintbtn() {
  const [errormsg, setErrorMsg] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [totalSupply, settotalSupply] = useState(0);
  const [walletConnected, setWalletConnected] = useState(0);
  const [whitelistedUser, setWhitelistedUser] = useState(0);
  const [userAddress, setUserAddress] = useState("");
  const [popview, setpopview] = useState("none");

  useEffect(async () => {
    if (await detectEthereumProvider()) {
      // setProvider(true);
      window.web3 = new Web3(window.ethereum);
      const web3 = window.web3;
      if ((await web3.eth.net.getId()) == SELECTEDNETWORK) {
        const contractaddress = REACT_APP_CONTRACT_ADDRESS;
        const ct = new web3.eth.Contract(abi, contractaddress);
        settotalSupply(await ct.methods.totalSupply().call());

        if (nftquantity - (await ct.methods.totalSupply().call()) == 0) {
          setErrorMsg(
            <h1 style={{ fontSize: "30px", fontWeight: "bolder", color: "Black", margin: "140px 0px 0px 0px" }} >
              "All NFTs minted, Sale has ended"</h1>);
        }
      } else {
        // setProvider(false);
        setErrorMsg(
          <h1 style={{ fontSize: "30px", fontWeight: "bolder", color: "Black", margin: "140px 0px 0px 0px" }} >
            'Select "'{SELECTEDNETWORKNAME}           '" network in your wallet to buy the nft'</h1>
        );
      }
    } else {
      setErrorMsg(
        <h1 style={{ fontSize: "30px", fontWeight: "bolder", color: "Black", margin: "140px 0px 0px 0px" }} >
          "Non-Ethereum browser detected. You should consider trying MetaMask!"</h1>
      );
      // setProvider(false);
    }
    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });
      setTimeout(handleEthereum, 10000);
    }

    function handleEthereum() {
      const { ethereum } = window;
      if (ethereum) {
        console.log(
          "Ethereum successfully detected!");
        // setProvider(true);
      } else {
        setErrorMsg(
          <h1 style={{ fontSize: "30px", fontWeight: "bolder", color: "Black", margin: "140px 0px 0px 0px" }} >
            "Please install MetaMask!"</h1>);

        // setProvider(false);
      }
    }
  }, []);

  async function loadWeb3() {
    if (await detectEthereumProvider()) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      // Meta Mask Connected Account Address
      let metaMaskAccount = await web3.eth.getAccounts();
      metaMaskAccount = metaMaskAccount[0];

      if ((await web3.eth.net.getId()) == SELECTEDNETWORK) {
        // // creating contract instance
        const contractaddress = REACT_APP_CONTRACT_ADDRESS;
        const ct = new web3.eth.Contract(abi, contractaddress);
        console.log(contractaddress);

        console.log(ct);

        let current = await ct.methods.totalSupply().call();
        if (Number(current) === nftquantity) {
          // console.log("Sold out");
          return;
        }

        let SilverMint_price = await ct.methods.SilverPrice().call();
        console.log("Price = ", SilverMint_price)
        // let WhiteListSilverMint_price = await ct.methods.WhiteListSilverPrice().call();
        let ismintPaused = await ct.methods.ismintPaused().call();



        if (ismintPaused == false) {
          console.log("Mint Called")

          // let iswhitelist = await ct.methods.isWhiteListed(metaMaskAccount).call();
          // if (iswhitelist == true) {
          //   await ct.methods
          //     .WhitelistMint(quantity)
          //     .send({
          //       from: metaMaskAccount,
          //       value: WhiteListSilverMint_price * quantity,
          //     })
          // }
          // else {
          await ct.methods.mint(quantity).send({ from: metaMaskAccount, value: SilverMint_price * quantity, })


        }



        else {
          setErrorMsg(
            <h1 style={{ fontSize: "30px", fontWeight: "bolder", color: "Black", margin: "140px 0px 0px 0px" }} >
              Sale Has Not started yet
            </h1>
          );
        }


        settotalSupply(await ct.methods.totalSupply().call());
        setQuantity(1);
      } else {
        setErrorMsg(
          <h1 style={{ fontSize: "30px", fontWeight: "bolder", color: "Black", margin: "140px 0px 0px 0px" }} >

            'Select "'
            Ethereum Network
            '" network in your wallet to buy the nft'</h1>
        );
      }
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      // window.alert(
      //   "Non-Ethereum browser detected. You should consider trying MetaMask!"
      // );
      {
        setErrorMsg(
          <h1 style={{ fontSize: "30px", fontWeight: "bolder", color: "Black", margin: "100px 0px 0px 0px" }} >

            "Non-Ethereum browser detected. You should consider trying MetaMask!"</h1>
        );
      }
    }
  }

  return (
    <div>
      {!errormsg ? (
        <div className="row mintingsection">
          <div className="col-sm-12">
            <div className="yellow">


              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "auto", width: "400px" }} className="mt-2">
                <h3 style={{ fontSize: "30px", fontWeight: "bolder", color: "Black", margin: "20px 0px 0px 0px" }} className="">SENSEMAP</h3>
                <h3 style={{ fontSize: "20px", textAlign: "center", fontWeight: "bolder", color: "Golden", margin: "0px 0px 0px 0px" }} className="">
                  <br></br>
                  SILVER PACKAGE
                </h3>




                <h3 style={{ fontSize: "40px", fontWeight: "bolder", color: "Golden", margin: "20px 0px 0px 0px" }} className="">Quantity</h3>
                {/* <div style={{ marginLeft: "10px" }}> */}
                <div>
                  <button className="minus back-button btn-warning px-3 mx-1"
                    onClick={() => {
                      if (quantity > 1) {
                        setQuantity(quantity - 1);
                      }
                    }}
                  >-</button>
                  <span style={{ fontSize: 30, margin: "0 15px", fontWeight: "bolder", color: "red" }}>
                    {quantity}
                  </span>
                  <button
                    className="plus back-button btn-warning px-3 mx-1"
                    onClick={() => {
                      if (quantity < 6) {
                        setQuantity(quantity + 1);
                      }
                    }}
                  >+</button>
                </div>
              </div>
            </div>
            <button style={{
              cursor: "pointer",
              backgroundColor: "Black",
              color: "#d5ac1e",
              padding: "5px 20px",
              borderRadius: "6px",
              width: '405px',
              height: '62px',
              fontSize: '35px'
            }}
              className="mt-3 mint-btn mx-auto d-block"
              onClick={() => {
                loadWeb3();
              }}
            >Mint</button>
            {/* <h5 className="mt-2 supplytext text-center">{nftquantity - totalSupply}/{nftquantity} Available</h5> */}
          </div>
        </div>) : <h5 style={{ display: "flex", justifyContent: "center" }} className="mt-2 supplytext" ><b>{errormsg}</b></h5>
      }
    </div >
    // <div className="BtnDiv ">
    //   {!errormsg ? (
    //     <div className="align-items-center">
    //       {walletConnected == 0 ? (
    //         <div className="">
    //           <h4
    //             onClick={() => {
    //               loadWeb3();
    //             }}
    //             className="text-white text-center d-block w-100"
    //           >
    //             <a
    //               style={{
    //                 cursor: "pointer",
    //                 backgroundColor: "white",
    //                 color: "#d5ac1e",
    //                 padding: "5px 20px",
    //                 borderRadius: "6px",
    //               }}
    //             >
    //               Mint Now
    //             </a>
    //           </h4>
    //         </div>
    //       ) : (
    //         ""
    //       )}
    //     </div>
    //   ) : (
    //     <h5 className="mt-2 supplytext text-center">
    //       <b>{errormsg}</b>
    //     </h5>
    //   )}

    // </div>
  );
}

export default Mintbtn;
